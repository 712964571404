// project import
// import NavCard from './NavCard';
import Navigation from './Navigation';
import SimpleBar from 'components/third-party/SimpleBar';
import { Grid, Typography } from '@mui/material';
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useDispatch } from 'react-redux';
import { isBrowser, downloadApp } from 'common';
import { Logo } from 'components/icons'
import React, { useEffect } from 'react';
import { Language } from 'language';
import ChangeLanguage from 'language/ChangeLanguage';
// ==============================|| DRAWER CONTENT ||============================== //

const DrawerContent = () => {
	const theme = useTheme();
	const isRunningInBrowser = isBrowser();

	useEffect(() => { }, []);
	return (
		<SimpleBar
			sx={{
				'& .simplebar-content': {
					display: 'flex',
					flexDirection: 'column'
				},
				height: '100%'
			}}
		>
			<Navigation position={"top"} />
			<Grid container spacing={0}>
				<Grid item xs={12} style={{ borderTop: '1px solid #EAEBEE', paddingTop: '15px' }}>
					<Typography variant="body1" gutterBottom>
						<Typography variant="caption">{Language.label.settings}</Typography>
					</Typography>
				</Grid>
				<Grid item xs={12}>
					<ChangeLanguage />
				</Grid>
			</Grid>
			<Navigation position={"bottom"} />
			{isRunningInBrowser && downloadApp(30)}
		</SimpleBar>
	);
};

export default DrawerContent;
