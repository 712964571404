import * as React from 'react';
import axios from 'axios';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Typography } from '@mui/material';
import { useState } from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import { dispatchCustomEvent } from 'EventDispatcher';
import { Language, LangKeyValues } from 'language';
import ChangeLanguageIcon from './changeLanguageIcon';
import { authHeaders } from 'common';
import urls from 'backend_urls';

const styles = {
  button: {
    color: '#000000',
    width: '100%',
    justifyContent: 'left',
  },
  formControl: {
    width: '100%',
  },
  submitButton: {
    width: '100%',
  },
};

const ChangeLanguage = () => {
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState('');

  const updateUserLanguage = async (lang) => {
    const headers = authHeaders()
    if (headers.Authorization) {
      await axios
        .get(`${urls.updateUserDetails}language/${lang}/`, { headers: authHeaders() })
    }
  };

  const handleClickOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleRadioChange = ({ target: { value } }) => setValue(value);

  const handleDispatchEvent = async (lang) => {
    localStorage.setItem('selectedLanguage', lang);
    const eventData = { lang };
    await updateUserLanguage(lang)
    dispatchCustomEvent('updatedLanguage', eventData);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const valueSplit = value.split('-')
    handleDispatchEvent(valueSplit[0]);

    handleClose();
  };

  return (
    <React.Fragment>
      <Button variant="text" onClick={handleClickOpen} style={styles.button}>
        <Typography display="flex">
          <div style={{ marginRight: '8px' }}>
            <ChangeLanguageIcon />
          </div>
          <div style={{textAlign:"left"}}>
            <Typography variant="body1" color="initial"> {Language.label.change_language}</Typography>
            <Typography variant="body1" color="initial" style={{ fontSize: "10px", color: "#7D8597", fontWeight: "500" }}>{"Transfer between different languages"}</Typography>
          </div>
        </Typography>
      </Button>
      <Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth={300}>
        <DialogTitle variant="h3">{Language.label.select_language}</DialogTitle>
        <DialogContent>
          <form onSubmit={handleSubmit}>
            <FormControl sx={styles.formControl} variant="standard">
              <RadioGroup aria-labelledby="demo-error-radios" name="lang-selection" value={value} onChange={handleRadioChange}>
                {LangKeyValues.map((language, index) => (
                  <FormControlLabel key={`language-${index}`} value={`${language.code}-${language.name.toLowerCase()}`} control={<Radio />} label={language.localeName} />
                ))}
              </RadioGroup>
              <Button type="submit" onClick={handleClose} variant="contained" sx={styles.submitButton}>
                {Language.button.select}
              </Button>
            </FormControl>
          </form>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
};

export default ChangeLanguage;
